import axios from 'axios';
import { bindActionCreators } from 'redux';
import * as common from '../../redux/commonReducer';
import * as auth from '../../app/modules/Auth/_redux/authRedux';
import store from '../../redux/store';
import { APILINK } from '../../config/config';

const apiHost = APILINK;

let apiCount = 0;
export const Repository = axios.create({
  baseURL: apiHost,
  headers: {
    Accept: 'application/json',
    'content-Type': 'application/json'
  },
  withCredentials: true
});

export const requestinterceptors = (dispatch) => {
  const commonActions = bindActionCreators(common.actions, dispatch);
  Repository.interceptors.request.use(
    (request) => {
      const {
        auth: { authToken }
      } = store.getState();
      if (authToken) {
        request.headers.Authorization = `Bearer ${authToken}`;
      }

      if (request.headers.noLoader) {
        return request;
      }
      apiCount++;
      commonActions.showLoader(true);
      return request;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};
export const responseinterceptors = (dispatch) => {
  const authActions = bindActionCreators(auth.actions, dispatch);
  const commonActions = bindActionCreators(common.actions, dispatch);
  Repository.interceptors.response.use(
    (response) => {
      if (response.config.headers.noLoader) {
        return response.data;
      }
      apiCount--;
      commonActions.showLoader(apiCount > 0);
      return response.data;
    },
    (error) => {
      console.warn(error);
      if (error.response || error.request) {
        const data = error.response || error.request;
        if (data.data && data.data.message) {
          commonActions.showSnackBar(true, data.data.message, 'error');
        }
        switch (data.status) {
          case 400:
            //show message wit data.data.message
            break;
          case 401:
            authActions.logout();
            // move to login page
            break;
          case 403:
            //access denied
            break;
          default:
            break;
        }
      }

      if (error.response.config.headers.noLoader) {
        return Promise.reject(error);
      }
      apiCount--;
      commonActions.showLoader(apiCount > 0);
      return Promise.reject(error);
    }
  );
};
