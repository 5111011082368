import { Repository } from '../servics/httpsServics';
const quickGuide = '/quick-guide';

export function getQuickGuideText(data) {
  return Repository.get(`${quickGuide}/text`, { params: data });
}
export function createQuickGuide(data) {
  return Repository.post(quickGuide, data);
}

export function getQuickGuidesListWithFilterPagination(data) {
  return Repository.get(`${quickGuide}/list`, { params: data });
}

export function deleteQuickGuide(id) {
  return Repository.delete(`${quickGuide}/${id}`);
}

export function updateQuickGuide(data, id) {
  return Repository.put(`${quickGuide}/${id}`, data);
}
