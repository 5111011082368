import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

function DeletePopup(props) {
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);

  const handleDelete = () => {
    setIsDeleteDisabled(true);
  };

  useEffect(() => {
    if (isDeleteDisabled) props.handleOk();
  }, [isDeleteDisabled]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={props.open}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{props.msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <button type="button" onClick={props.handleCancel} className={props.cacelColor}>
          {props.cancelText}
        </button>

        <button
          type="button"
          onClick={handleDelete}
          className={props.okColor}
          disabled={isDeleteDisabled}
        >
          {props.okText}
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default DeletePopup;
