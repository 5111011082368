import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import '../../../_assets/sass/layout/_quick-guide.scss';
import { toAbsoluteUrl } from '../../../_helpers';

const QuickGuide = React.memo(({ onCloseClick, guideText }) => {
  return (
    <div className="quick-guide mb-4">
      <div className="quick-guide__body mx-2">
        <div className="quick-guide__content-wrapper">
          <div className="quick-guide__icon-wrapper">
            <SVG src={toAbsoluteUrl('/media/svg/shapes/hexagon.svg')} />
            <div className="quick-guide__icon-text">?</div>
          </div>
          <div className="font-weight-bold pl-4">{guideText}</div>
        </div>
        <button type="button" className="quick-guide__close-icon" onClick={onCloseClick}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
});
QuickGuide.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  guideText: PropTypes.string.isRequired
};
export default QuickGuide;
