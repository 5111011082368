import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_ens/_helpers';
import { LINKAPPSTORE } from '../../../../config/config';

function VideoNotAvailableIphoneModel(props) {
  useEffect(() => {}, [props]);

  return (
    <Modal
      centered={props.center}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Utilizzo delle Videochiamate su iPhone
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contact-modal">
          <div>
            <div>
              <div>
                <div>
                  <h3>
                    <div>
                      Gli <b>Utenti</b> che desiderano effettuare videochiamate tramite iPhone
                      devono utilizzare l'apposita app.
                    </div>
                    <div className="mt-4">
                      Gli <b>Interpreti</b>, invece, devono accedere al servizio utilizzando un tablet o un
                      PC per garantire la migliore esperienza possibile.
                    </div>
                  </h3>
                  <p className="red-title mt-8 text-center">
                    <a href={LINKAPPSTORE}>
                      <img
                        alt="Logo"
                        className=""
                        style={{ width: '200px' }}
                        src={toAbsoluteUrl('/media/logos/appstore.png')}
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
          <FormattedMessage id="CANCEL" />
        </button>

        <> </>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(VideoNotAvailableIphoneModel);
