import store from '../../redux/store';

const ParentUrls = {
  USER: '/',
  INTERPRATOR: '/Interpreter/',
  ADMIN: '/admin/'
};

export const weekDays = [
  { name: 'SUNDAY', value: 0 },
  { name: 'MONDAY', value: 1 },
  { name: 'TUESDAY', value: 2 },
  { name: 'WEDNESDAY', value: 3 },
  { name: 'THRUSDAY', value: 4 },
  { name: 'FRIDAY', value: 5 },
  { name: 'SATURDAY', value: 6 }
];

export const AllPermissions = {
  ADDAPOINTMENT: 'addAppointment',
  VIEWUSERLIST: 'viewUserList',
  VIEWINTERPRATORLIST: 'viewInterpratorList',
  PENDINGAPPOINTMENT: 'pendingappointment',
  SETTINGS: 'settings',
  ADDAVAILBILITY: 'addavailibility',
  ADDCALLAVAILBILITY: 'addcallavailibility',
  VIDEOCALL: 'videocall',
  VIDEOCALLSINGLE: 'videocallsingle',
  CALCULATIONLIST: 'calculationList',
  UPDATEAPPOINTMENTTIME: 'updateAppointmentTime',
  CHATMESSAGE: 'chatMessage',
  ADMINCHATMESSAGE: 'adminchatmessage',
  CHATMESSAGEWITHADMIN: 'chatMessageWithAdmin',
  AFFILIATEDSTRUCTURES: 'affiliatedStructures',
  FAQS: 'faqs',
  SUPPORT: 'support',
  QUICKGUIDES: 'quickGuides',
  SHOWAPPOINTMENTS: 'showAppointments'
};

const UserPermissions = {
  User: {
    addAppointment: true,
    viewUserList: false,
    viewInterpratorList: false,
    pendingappointment: false,
    settings: false,
    addavailibility: false,
    addcallavailibility: false,
    videocall: true,
    videocallsingle: true,
    calculationList: false,
    updateAppointmentTime: false,
    chatMessage: true,
    adminchatmessage: false,
    chatMessageWithAdmin: true,
    faqs: false,
    support: true,
    quickGuides: false,
    showAppointments: false
  },
  Admin: {
    addAppointment: false,
    viewUserList: true,
    viewInterpratorList: true,
    pendingappointment: true,
    settings: true,
    addavailibility: false,
    addcallavailibility: false,
    videocall: false,
    videocallsingle: false,
    calculationList: true,
    updateAppointmentTime: true,
    chatMessage: false,
    adminchatmessage: true,
    chatMessageWithAdmin: false,
    affiliatedStructures: true,
    faqs: true,
    support: false,
    quickGuides: true,
    showAppointments: false
  },
  Interpreter: {
    addAppointment: false,
    viewUserList: false,
    viewInterpratorList: false,
    pendingappointment: false,
    settings: false,
    addavailibility: true,
    addcallavailibility: true,
    videocall: true,
    videocallsingle: true,
    calculationList: true,
    updateAppointmentTime: false,
    chatMessage: true,
    adminchatmessage: false,
    chatMessageWithAdmin: true,
    faqs: false,
    support: true,
    quickGuides: false,
    showAppointments: true
  }
};

export function getParentRoute() {
  let redirectURL = ParentUrls.USER;
  let user = store.getState().auth.user;
  if (user) {
    if (user.role === 'User') redirectURL = ParentUrls.USER;
    else if (user.role === 'Admin') redirectURL = ParentUrls.ADMIN;
    else redirectURL = ParentUrls.INTERPRATOR;
  }
  return redirectURL;
}

export function checkUserPermision(permission) {
  if (permission) {
    let user = store.getState().auth.user;
    if (user && UserPermissions[user.role] && UserPermissions[user.role][permission]) {
      return UserPermissions[user.role][permission];
    }
  }
  return false;
}

export function isAdmin() {
  let user = store.getState().auth.user;
  if (user) {
    if (user.role === 'Admin') return true;
  }
  return false;
}

export function isInterpreter() {
  let user = store.getState().auth.user;
  if (user) {
    if (user.role === 'Interpreter') return true;
  }
  return false;
}

export function isUser() {
  let user = store.getState().auth.user;
  if (user) {
    if (user.role === 'User') return true;
  }
  return false;
}

export function isAdminVerified() {
  let user = store.getState().auth.user;
  if (user) {
    if (user.adminVerified) return true;
  }
  return false;
}

export function getSettingData() {
  let settingData = store.getState().settingData.value;
  let user = store.getState().auth.user;

  if (user) {
    if (
      settingData &&
      settingData.hasOwnProperty('chat_admin') &&
      settingData['chat_admin'] &&
      settingData.chat_admin.includes(user.id)
    )
      return true;
  }
  return false;
}
