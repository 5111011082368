import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Calendar from './Calendar';

import { toAbsoluteUrl } from '../../_ens/_helpers';
import { getAdminSettings } from '../modules/Admin/AdminActions';
import { KTUtil } from '../../_ens/_assets/js/components/util';
import IphoneModel from '../modules/Auth/pages/IphoneModel';

import VideoNotAvailableIphoneModel from '../modules/Auth/pages/VideoNotAvailableIphoneModel';

export const Wrapper = ({ auth, history }) => {
  const [showModal, setShowModal] = useState(false);
  const [disableVideo, setDisableVideo] = useState(true);
  const [modalIphoneShow, setModelIphoneShow] = useState(false);

  const onModelIphoneHide = () => {
    setModelIphoneShow(false);
  };

  useEffect(() => {
    if (KTUtil.isIphoneDevice()) {
      setModelIphoneShow(true);
    }
    getAdminSettings().then((res) => {
      setDisableVideo(res.data.disableVideo);
    });
  }, []);

  const onCall = () => {
    if (auth.user.role === 'User') {
      history.push('/call');
    } else {
      history.push('/interpreter/call');
    }
  };

  return (
    <>
      {auth.user.role === 'Admin' || disableVideo ? (
        <Calendar />
      ) : (
        <>
          {/* <div className="dashboard-wrapper w-100">
            <div className="text-center">
              <button
                className="btn btn-primary mr-2 c_big_btn"
                onClick={() =>
                  history.push(auth.user.role === 'User' ? '/calendar' : '/Interpreter/calendar')
                }
              >
                <i className="far fa-calendar-check"></i>
                Appointment On Site
              </button>
              <button className="btn btn-primary c_big_btn" onClick={() => setShowModal(true)}>
                <i className="fas fa-headset"></i>
                Call Online
              </button>
            </div>
          </div> */}

          <div className="container" style={{ maxWidth: '100%', padding: 0 }}>
            <div className="row mb-10">
              <div className="col-md-12 text-center" style={{ fontSize: '40px' }}>
                Scegli un servizio
              </div>
            </div>
            <div className="row pl-30 pr-30">
              <div className="col-md-6">
                <div className="sn_full_height sn_left_section">
                  <div
                    className="sn_content"
                    onClick={() =>
                      history.push(
                        auth.user.role === 'User' ? '/calendar' : '/Interpreter/calendar'
                      )
                    }
                  >
                    <img
                      src={toAbsoluteUrl('/media/image/onsite.png')}
                      style={{ maxWidth: '90%' }}
                      alt={'onsite image icon'}
                    />
                    <br />
                    INTERPRETARIATO SU PRENOTAZIONE
                    <br />
                    IN PRESENZA E A DISTANZA
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sn_full_height sn_right_section">
                  <div className="sn_content" onClick={() => setShowModal(true)}>
                    <img
                      src={toAbsoluteUrl('/media/image/online.png')}
                      style={{ maxWidth: '90%' }}
                      alt={'online image icon'}
                    />
                    <br />
                    CHIAMATA DIRETTA INTERPRETE
                    <br />
                    SENZA PRENOTAZIONE
                  </div>
                </div>
              </div>
            </div>
          </div>

          {modalIphoneShow ? (
            <VideoNotAvailableIphoneModel
              center={true}
              show={modalIphoneShow}
              onHide={onModelIphoneHide}
            />
          ) : (
            ''
          )}

          <Modal
            show={showModal}
            onHide={() => setShowModal(!showModal)}
            dialogClassName="modal-dialog-centered modal-90w"
          >
            <Modal.Body className="text-center">
              <h4>Vuoi iniziare la Videochiamata?</h4>
              <div className="confirm-btn">
                <Button variant="primary" className="mr-2" onClick={() => onCall()}>
                  Prosegui
                </Button>
                <Button variant="danger" onClick={() => setShowModal(!showModal)}>
                  Annulla
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Wrapper);
