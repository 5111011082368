/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Tooltip as TooltipCore } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Dropdown, Nav, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { FormattedMessage, injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAffiliatedStructureList,
  getMsgCount,
  getpendingReqs,
  getUserList,
  updateAppointmentStatus
} from '../../../../../app/modules/Admin/AdminActions';
import AssignAppointment from '../../../../../app/modules/Admin/PendingReq/AssignAppointment';
import AcceptPopup from '../../../../../app/pages/Popup/UpdateAppointment';
import { isAdmin, isInterpreter } from '../../../../../app/servics/CommonFunctions';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

function UserNotificationsDropdown(props) {
  let history = useHistory();

  const { intl } = props;
  var timeruno = 0;
  var timerdue = 0;
  const [key, setKey] = useState('Alerts');
  const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg');

  useEffect(() => {
    if (props.common.modalOpen) {
      clearInterval(timeruno);
      clearInterval(timerdue);
    } else {
      startTimer();
      getPendingAppointments();
      getPendingMsg();
      if (isAdmin()) getInterpratorList();
    }

    getAffiliatedStructureList().then((res) => {
      let affOption1 = [];
      affOption1.push({
        label: intl.formatMessage({
          id: 'DASHBOARD_SELECT_AFFILIATED'
        }),
        value: ''
      });
      res.data.forEach((aff) => {
        affOption1.push({ label: aff.name, value: aff.id });
      });
      setAffiliatedOption(affOption1);
    });

    return () => {
      clearInterval(timeruno);
      clearInterval(timerdue);
    };
  }, [props.common.modalOpen]);

  const [totoalPending, setTotalPending] = useState(null);
  const [totalMsgCount, setTotalMsgCount] = useState(null);
  const [pendingList, setPendingList] = useState([]);
  const [interpreterList, setinterpreterList] = useState([]);
  const [appointmentData, setappointmentData] = useState({});
  const [modalShow, setModelShow] = useState(false);
  const [affiliatedOption, setAffiliatedOption] = useState([]);

  const getInterpratorList = () => {
    getUserList('Interpreter').then((res) => {
      setinterpreterList(res.data);
    });
  };

  const startTimer = () => {
    timeruno = setInterval(getPendingAppointments, 120000);
    timerdue = setInterval(getPendingMsg, 120000);
  };

  const getPendingAppointments = () => {
    getpendingReqs().then((res) => {
      setTotalPending(res.data.length);
      setPendingList(res.data);
    });
  };

  const getPendingMsg = () => {
    getMsgCount({ id: props.auth?.user?.id }).then((res) => {
      setTotalMsgCount(res.data.count_msg);
    });
  };

  const onModelHide = () => {
    setModelShow(false);
  };

  const handleClick = (data) => {
    if (isAdmin()) {
      setappointmentData({
        ...data.appointment,
        affiliatedOption: affiliatedOption,
        interpreterId: data.interpreterID !== 0 ? data.interpreterID : ''
      });
    } else {
      setappointmentData({
        ...data.appointment,
        affiliatedOption: affiliatedOption,
        id: data.id,
        status: data.status,
        ...data
      });
    }
    setModelShow(true);
  };

  const handleDelete = (id) => {
    updateAppointmentStatus({
      id,
      status: 3
    }).then((res) => {
      getPendingAppointments();
    });
  };

  return (
    <>
      {isInterpreter() && modalShow ? (
        <AcceptPopup
          modalShow={modalShow}
          appointmentModel={appointmentData}
          onModelHide={onModelHide}
          updateCurrentUser={getPendingAppointments}
        ></AcceptPopup>
      ) : null}

      {modalShow && isAdmin() ? (
        <AssignAppointment
          modalShow={modalShow}
          appointmentModel={appointmentData}
          interpreterList={interpreterList}
          updateCurrentUser={() => {
            setModelShow(false);
            getPendingAppointments();
          }}
          onModelHide={onModelHide}
        ></AssignAppointment>
      ) : null}

      <div
        className="dropdown"
        onClick={() =>
          history.push(
            props.auth.user.role === 'Admin'
              ? '/admin/adminChatMessage'
              : '/Interpreter/chatMessageWithAdmin'
          )
        }
      >
        <div className="topbar-item">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="user-notification-tooltip">
                <FormattedMessage id="NOTIFICATION.NOTIFICATION_HOVER_ADMIN" />
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat4.svg')} />
                {totalMsgCount > 0 ? <>{totalMsgCount}</> : null}
              </span>
              <span className="pulse-ring"></span>
              <span className="pulse-ring" />
            </div>
          </OverlayTrigger>
        </div>
      </div>

      <Dropdown drop="down" >
        <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="user-notification-tooltip">
                <FormattedMessage id="NOTIFICATION.NOTIFICATION_HOVER" />
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
                {totoalPending > 0 ? <>{totoalPending}</> : null}
              </span>
              <span className="pulse-ring"></span>
              <span className="pulse-ring" />
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h4 className="d-flex flex-center rounded-top">
                <span className="text-white">
                  <FormattedMessage id="NOTIFICATION.TITLE" />
                </span>
                <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                  {totoalPending} <FormattedMessage id="NOTIFICATION.NEW" />
                </span>
              </h4>

              <Tab.Container defaultActiveKey={key}>
                <Nav
                  as="ul"
                  className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                  onSelect={(_key) => setKey(_key)}
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      eventKey="Alerts"
                      className={`nav-link show ${key === 'Alerts' ? 'active' : ''}`}
                    >
                      <FormattedMessage id="NOTIFICATION.ALERTS" />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="tab-content">
                  <Tab.Pane eventKey="Alerts" className="p-4">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="scroll"
                      style={{ maxHeight: '300px', position: 'relative' }}
                    >
                      {pendingList.length > 0 ? (
                        <>
                          {pendingList.map((item) => {
                            return (
                              <div
                                onClick={() => handleClick(item)}
                                key={item.id}
                                className="d-flex align-items-center mb-6"
                              >
                                <div className="symbol symbol-40 symbol-light-primary mr-5">
                                  <span className="symbol-label">
                                    {isInterpreter() &&
                                    moment().isAfter(
                                      moment(
                                        `${item.appointment.final_date} ${item.appointment.final_end_time}`,
                                        'YYYY-MM-DD HH:mm:ss'
                                      )
                                    ) ? (
                                      <TooltipCore
                                        title={intl.formatMessage({
                                          id: 'TOOLTIP.EVENT.PAST_NOTIFICATION'
                                        })}
                                      >
                                        <AlarmIcon style={{ color: 'red' }} />
                                      </TooltipCore>
                                    ) : (
                                      <SVG
                                        src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')}
                                        className="svg-icon-lg svg-icon-primary"
                                      ></SVG>
                                    )}
                                  </span>
                                </div>
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href="#"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    {item.appointment.account.firstName}{' '}
                                    {item.appointment.account.lastName} (
                                    {moment(item.appointment.date, 'YYYY-MM-DD').format(
                                      'DD-MM-YYYY'
                                    )}
                                    )
                                  </a>
                                  {item.appointment.affiliatedstructure &&
                                  item.appointment.affiliatedstructure.name ? (
                                    <span className="text-muted">
                                      {item.appointment.affiliatedstructure.name}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  <span className="text-muted">{item.appointment.place}</span>
                                </div>
                                {isInterpreter &&
                                moment().isAfter(
                                  moment(
                                    `${item.appointment.final_date} ${item.appointment.final_end_time}`,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )
                                ) ? (
                                  <button
                                    className="d-flex close align-self-start"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleDelete(item.id);
                                    }}
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <FormattedMessage id="NOTIFICATION.NONOTIFICATIONS" />
                      )}
                    </PerfectScrollbar>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  common: state.common
});

export default injectIntl(connect(mapStateToProps)(UserNotificationsDropdown));
