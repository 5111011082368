import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { resendOtp, verifyOtp } from '../_redux/authCrud';
import * as common from '../../../../redux/commonReducer';
import * as auth from '../_redux/authRedux';
import queryString from 'query-string';

// for firebase notification device token set
// import { getToken } from '../../../../firebase_token';

const initialValues = {
  otp: ''
};

function OtpVerify(props) {
  var timer = 0;
  var seconds = 90;
  const [otpResendTime, setOtpResendTime] = useState('1:30');
  const [resendActive, setResendActive] = useState(false);

  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [firebaseTokenData, setFireBaseTokenData] = useState('');
  const ForgotOtpSchema = Yup.object().shape({
    otp: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
  });

  useEffect(() => {
    startTimer();
    // getFireBaseToken();
    return () => {
      clearInterval(timer);
    };
  }, []);

  // const getFireBaseToken = async () => {
  //   const resFirebase = await getToken();
  //   if (resFirebase !== false) {
  //     setFireBaseTokenData(resFirebase);
  //   }
  // };

  const startTimer = () => {
    if (timer === 0 && seconds > 0) {
      timer = setInterval(countDown, 1000);
    }
  };

  const countDown = () => {
    seconds = seconds - 1;
    let t = secondsToTime(seconds);
    setOtpResendTime(t.m + ' : ' + t.s);

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(timer);
      setResendActive(true);
    }
  };

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  const sendOtp = () => {
    const { email } = queryString.parse(window.location.search);
    resendOtp(email).then(
      (res) => {
        props.commonActions.showSnackBar(true, res.data.message);
        timer = 0;
        seconds = 90;
        setResendActive(false);
        startTimer();
      },
      (err) => {}
    );
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /*if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }*/

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotOtpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const { email, token } = queryString.parse(window.location.search);
      values.email = email;
      values.token = token;
      // values.firebase_token = firebaseTokenData;
      verifyOtp(values)
        .then((res) => {
          if (res.data.reset_password) {
            props.history.push('set-password?u=' + btoa(res.data.id));
          } else {
            setIsRequested(true);
            props.authActions.login(res.data.jwtToken);
            props.authActions.setUser(res.data);
            props.history.push({
              pathname: '/'
            });
          }
        })
        .finally(() => {
          setIsRequested(false);
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.OTP.TITLE" />
            </h3>
            <div className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.OTP.DESC" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({
                  id: 'AUTH.OTP.OTP'
                })}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'otp'
                )}`}
                name="otp"
                {...formik.getFieldProps('otp')}
              />
              {formik.touched.otp && formik.errors.otp ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.otp}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="SUBMIT" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="CANCEL" />
                </button>
              </Link>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              {resendActive ? (
                <a
                  onClick={() => sendOtp()}
                  className="text-dark-50 text-hover-primary my-3 mr-2 font-weight-bold"
                >
                  <FormattedMessage id="AUTH.OTP.RESENDOTP" />
                </a>
              ) : (
                <span>
                  <FormattedMessage id="AUTH.OTP.RESENDOTPIN" />
                  {otpResendTime}
                </span>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch),
    authActions: bindActionCreators(auth.actions, dispatch)
  };
};

export default connect(null, mapDispatchToEvents)(injectIntl(OtpVerify));
