import React, { useState, useEffect } from 'react';
import { useSync } from '@tldraw/sync';
import { AssetRecordType, getHashForString, Tldraw, uniqueId } from 'tldraw';
import { useLocation } from 'react-router-dom';
import { BLACKBOARD_URL } from '../../../../config/config';

const WORKER_URL = BLACKBOARD_URL;

const bufferToHex = (buffer) => {
  return Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};

async function calculateSHA1(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgBuffer);
  return bufferToHex(hashBuffer);
}

const BoardContent = ({ videoCode }) => {
  const store = useSync({
    // We need to know the websocket's URI...
    uri: `${WORKER_URL}/connect/${videoCode}`,
    // ...and how to handle static assets like images & videos
    assets: multiplayerAssets
  });

  return (
    <div
      className={`blackboard-container ${(videoCode) ? 'show' : 'hide'}`}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div className="blackboard-body" style={{ flex: 1, position: 'relative' }}>
        <Tldraw
          store={store}
          onMount={(editor) => {
            editor.registerExternalAssetHandler('url', unfurlBookmarkUrl);
          }}
        />
      </div>
    </div>
  );
};

// How does our server handle assets like images and videos?
const multiplayerAssets = {
  // to upload an asset, we prefix it with a unique id, POST it to our worker, and return the URL
  async upload(_asset, file) {
        const id = uniqueId();
        const objectName = `${id}-${file.name}`;
        const url = `${WORKER_URL}/uploads/${encodeURIComponent(objectName)}`;
        const response = await fetch(url, {
          method: 'PUT',
      body: file
        });
        if (!response.ok) {
          throw new Error(`Failed to upload asset: ${response.statusText}`);
        }
        return url;
      },
  // to retrieve an asset, we can just use the same URL. you could customize this to add extra
  // auth, or to serve optimized versions / sizes of the asset.
      resolve(asset) {
        return asset.props.src;
  }
};

// How does our server handle bookmark unfurling?
  async function unfurlBookmarkUrl({ url }) {
    const asset = {
      id: AssetRecordType.createId(getHashForString(url)),
      typeName: 'asset',
      type: 'bookmark',
      meta: {},
      props: {
        src: url,
        description: '',
        image: '',
        favicon: '',
      title: ''
    }
    };

    try {
      const response = await fetch(`${WORKER_URL}/unfurl?url=${encodeURIComponent(url)}`);
      const data = await response.json();
    asset.props.description = data?.description ?? '';
    asset.props.image = data?.image ?? '';
    asset.props.favicon = data?.favicon ?? '';
    asset.props.title = data?.title ?? '';
    } catch (e) {
      console.error(e);
    }
    return asset;
  }


const FrameBoard = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();

  useEffect(() => {
    const checkAuthorization = async () => {
      const params = new URLSearchParams(location.search);
      const firstname = params.get('firstname');
      const email = params.get('email');
      const security = params.get('security');
      const videoCode = params.get('videoCode');

      if (!firstname || !email || !security || !videoCode) {
        setError("Parametri mancanti nell'URL");
        return;
      }

      const fullName = `${firstname}${email}`.toLowerCase();
      try {
        const hash = await calculateSHA1(fullName);
        if (hash === security) {
          setIsAuthorized(true);
        } else {
          setError('Accesso non autorizzato');
        }
      } catch (err) {
        setError("Errore durante la verifica dell'autorizzazione");
      }
    };

    checkAuthorization();
  }, [location]);

  if (error) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-xl">Verifica in corso...</div>
      </div>
    );
  }

  const videoCode = new URLSearchParams(location.search).get('videoCode');
  return <BoardContent videoCode={videoCode} />;
};

export default FrameBoard;
