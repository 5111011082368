import React, { lazy, Suspense } from 'react';
// import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '../_ens/layout';
import { MyPage } from './pages/MyPage';
// import DashboardPage from './pages/DashboardPage';
import DashboardWrapper from './pages/DashboardWrapper';
import {
  AllPermissions,
  checkUserPermision,
  getParentRoute,
  getSettingData
} from './servics/CommonFunctions';
import ErrorPage from '../app/modules/Errors/ErrorPage';
import DashboardPage from './pages/Calendar';

const UserProfilepage = lazy(() => import('./modules/UserProfile/UserProfilePage'));

const ActiveUserListingPage = lazy(() => import('./modules/Admin/Users/ActiveUserList'));

const InactiveUserListingPage = lazy(() => import('./modules/Admin/Users/InActiveUserList'));

const InterpreterListingPage = lazy(() => import('./modules/Admin/Users/InterpreterList'));

const AffiliatedStructuresPage = lazy(() =>
  import('./modules/Admin/AffiliatedStructures/AffiliatedStructures')
);

const PendingListingPage = lazy(() => import('./modules/Admin/PendingReq/pendingReqs'));

const VideoCallPage = lazy(() => import('./pages/VideoCall/VideoCallPage'));

const VideoCallSinglePage = lazy(() => import('./pages/VideoCallSingle/VideoCallSinglePage'));

const CalculatingList = lazy(() => import('./modules/Admin/calculatingList/calculatingList'));

const UpdateChangeAppointment = lazy(() => import('./modules/Admin/updateChangeAppointment/index'));

const ShowAppointments = lazy(() => import('./modules/InterpreterAppointments/index'));

const UpdateTimeAppointmentAdminForm = lazy(() =>
  import('./modules/Admin/updateChangeAppointment/form')
);

const RoomList = lazy(() => import('./pages/ChatMessage/RoomList'));
const AdminChatRoomList = lazy(() => import('./pages/AdminChatMessage/AdminChatRoomList'));

const UserInterpreterMessageView = lazy(() =>
  import('./pages/AdminChatMessage/UserInterpreterMessageView')
);

const FaqListingPage = lazy(() => import('./modules/Admin/Faq/FaqList'));
const FAQSupport = lazy(() => import('./pages/FAQ/FAQSupport'));
const QuickGUideListingPage = lazy(() => import('./modules/Admin/QuickGuide/QuickGuideList'));

// export let socket;

function BasePage() {
  let redirectUrl = getParentRoute();
  const isAdminAllowed = getSettingData();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={redirectUrl + 'dashboard'} />
        }
        <Redirect exact from={redirectUrl} to={redirectUrl + 'dashboard'} />

        {/* <ContentRoute path={redirectUrl + 'dashboard'} component={DashboardPage} /> */}
        <ContentRoute path={redirectUrl + 'dashboard'} component={DashboardWrapper} />
        <ContentRoute path={redirectUrl + 'calendar'} component={DashboardPage} />
        <ContentRoute path={redirectUrl + 'my-page'} component={MyPage} />

        <Route path={redirectUrl + 'user-profile'} component={UserProfilepage} />

       

        {checkUserPermision(AllPermissions.SHOWAPPOINTMENTS) ? (
          <Route path={redirectUrl + 'show-appointments'} component={ShowAppointments} />
        ) : null}
        
        {checkUserPermision(AllPermissions.CHATMESSAGE) ? (
          <Route path={redirectUrl + 'chatMessage'} component={RoomList} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermissions.ADMINCHATMESSAGE) && isAdminAllowed ? (
          <Route path={redirectUrl + 'adminChatMessage'} component={AdminChatRoomList} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermissions.CHATMESSAGEWITHADMIN) ? (
          <Route
            path={redirectUrl + 'chatMessageWithAdmin'}
            component={UserInterpreterMessageView}
          />
        ) : null}

        {checkUserPermision(AllPermissions.CALCULATIONLIST) ? (
          <Route path={redirectUrl + 'calculationList'} component={CalculatingList} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermissions.VIDEOCALL) ? (
          <ContentRoute path={redirectUrl + 'call'} component={VideoCallPage} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermissions.VIDEOCALLSINGLE) ? (
          <ContentRoute path={redirectUrl + 'singlecall'} component={VideoCallSinglePage} />
        ) : // <Redirect to={redirectUrl + 'error'} />
        null}

        {checkUserPermision(AllPermissions.SUPPORT) ? (
          <Route path={redirectUrl + 'support'} component={FAQSupport} />
        ) : null}

        {checkUserPermision(AllPermissions.VIEWUSERLIST) ? (
          <Route path={redirectUrl + 'activeUsers'} component={ActiveUserListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermissions.VIEWUSERLIST) ? (
          <Route path={redirectUrl + 'inactiveUsers'} component={InactiveUserListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermissions.VIEWINTERPRATORLIST) ? (
          <Route path={redirectUrl + 'interpreterList'} component={InterpreterListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermissions.AFFILIATEDSTRUCTURES) ? (
          <Route path={redirectUrl + 'affiliatedStructures'} component={AffiliatedStructuresPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermissions.PENDINGAPPOINTMENT) ? (
          <Route path={redirectUrl + 'pendingreqs'} component={PendingListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        {checkUserPermision(AllPermissions.UPDATEAPPOINTMENTTIME) ? (
          <Route
            path={redirectUrl + 'update-appointment-time'}
            component={UpdateChangeAppointment}
          />
        ) : null}
        {checkUserPermision(AllPermissions.UPDATEAPPOINTMENTTIME) ? (
          <Route
            path={redirectUrl + 'update-appointment-time-form/:id'}
            component={UpdateTimeAppointmentAdminForm}
          />
        ) : null}

        {checkUserPermision(AllPermissions.FAQS) ? (
          <Route path={redirectUrl + 'faqs'} component={FaqListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}
        {checkUserPermision(AllPermissions.QUICKGUIDES) ? (
          <Route path={redirectUrl + 'quick-guide'} component={QuickGUideListingPage} />
        ) : (
          <Redirect to={redirectUrl + 'error'} />
        )}

        <Route path={redirectUrl + 'error'} component={ErrorPage} />

        <Redirect to={redirectUrl + 'error'} />
      </Switch>
    </Suspense>
  );
}

export default BasePage;
