import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ADDRESS,
  MAIL,
  MAILENS,
  MOBOBJ,
  MOBVIEW,
  SITEENS,
  TELOBJ,
  TELVIEW,
  TITLE
} from '../../../../config/config';

function ContactModel(props) {
  useEffect(() => {}, [props]);

  return (
    <Modal
      centered={props.center}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Contatti</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contact-modal">
          <div>
            <div>
              <div>
                <div>
                  <h3>
                    <span>EMAIL DEDICATA AL PROGETTO</span>
                  </h3>
                  <p className="red-title">
                    <a href={`mailto:${MAIL}`}>{MAIL}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div>
                  <h3 className="h3-style">
                    <span>
                      ENTE NAZIONALE PER LA PROTEZIONE E L’ASSISTENZA DEI SORDI ENS – ONLUS APS{' '}
                    </span>
                  </h3>
                  <p className="text">
                    <span>
                      <em>{TITLE}</em>
                    </span>
                  </p>
                  <p className="text">
                    <span>{ADDRESS}</span>
                  </p>
                  <p className="text">
                    <span>
                      Telefono:{' '}
                      <a href={`tel:${TELOBJ}`} target="_blank" rel="noopener">
                        {TELVIEW}
                      </a>
                    </span>
                  </p>
                  <p className="text">
                    <span>
                      Fax:{' '}
                      <a href={`tel:${MOBOBJ}`} target="_blank" rel="noopener">
                        {MOBVIEW}
                      </a>
                    </span>
                  </p>
                  <p className="text">
                    <span>
                      Email:{' '}
                      <a href={`mailto:${MAILENS}`} target="_blank" rel="noopener">
                        {MAILENS}
                      </a>
                    </span>
                  </p>
                  <p className="text">
                    <span>
                      Sito Web:{' '}
                      <a href={SITEENS} target="_blank" rel="noopener">
                        {SITEENS}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
          <FormattedMessage id="CANCEL" />
        </button>

        <> </>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(ContactModel);
