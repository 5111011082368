import { isNull } from 'lodash';

const moment = require('moment');

export function convertDecimalHoursToTimeString(value) {
  const decimalHours = Number(value);
  if (!isNaN(decimalHours) && !isNull(value)) {
    const duration = moment.duration(decimalHours, 'hours');
    let hours = Math.floor(duration.asHours());
    let minutes = duration.minutes();
    let seconds = duration.seconds();
    let milliseconds = duration.milliseconds();

    // Rounding seconds if milliseconds are 500 or more
    if (milliseconds >= 500) {
      seconds += 1;
    }

    // Check if rounding seconds makes a minute
    if (seconds >= 60) {
      seconds = 0;
      minutes += 1;
    }

    // Check if rounding minutes makes an hour
    if (minutes >= 60) {
      minutes = 0;
      hours += 1;
    }

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedTime;
  } else return value;
}

export function NumberFormatting(value, options) {
  const number = Number(value);
  if (!isNaN(number) && !isNull(value))
    return new Intl.NumberFormat('it-IT', {
      ...options,
      minimumFractionDigits: 2
    }).format(number);
  else return value;
}

export function CurrencyFormatting(value, currency = 'EUR') {
  return NumberFormatting(value, { currency, style: 'currency' });
}

export function DecimalFormatting(value, decimalPlaces = 2) {
  const number = Number(value);
  if (!isNaN(number) && !isNull(value)) {
    return number.toFixed(decimalPlaces);
  } else {
    return value;
  }
}

export function formatDate(date, format = 'DD/MM/YYYY HH:mm') {
  return moment(date).format(format);
}

export function isIpad() {
  return /iPad|Macintosh|MacIntel/.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
}
