import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import * as common from './commonReducer';
import * as customer from './UserReducer';
import * as videoPeer from './videoCallReducer';
import * as settingReducer from './settingReducer';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  common: common.reducer,
  customers: customer.reducer,
  videoPeer: videoPeer.reducer,
  settingData: settingReducer.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
