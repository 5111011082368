import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import {TERMSANDCONDITIONP1,TERMSANDCONDITIONP2} from '../../../../config/config';

function TermsAndConditionModel(props) {
  return (
    <Modal
      centered={props.center}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Termini & Condizioni</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>INFORMATIVA ai sensi degli artt. 13 e 14 del REGOLAMENTO UE 2016/679 (Regolamento Generale sulla protezione dei dati).</h4>
        <p>{TERMSANDCONDITIONP1}</p>
        <p>{TERMSANDCONDITIONP2}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
          <FormattedMessage id="CANCEL" />
        </button>

        <> </>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(TermsAndConditionModel);
