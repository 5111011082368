import * as React from 'react';
import queryString from 'query-string';
import { verifyEmail } from "../_redux/authCrud";
import { FormattedMessage, injectIntl } from "react-intl";
 function VerifyEmail() {


    const [verifiedSuccessfully,setVerified] = React.useState(false)

    React.useEffect(()=>{
        const { token } = queryString.parse(window.location.search)
        if(token){
            verifyEmail(token).then(()=>{
                setVerified(true)
            })
        }
    },[])



        return (

          <div className="login-form login-signin" style={{ display: "block" }}>
         {verifiedSuccessfully?

            <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.VERIFYEMAIL.TITLE" />


            </h3>
              <p className="text-muted font-weight-bold">
                <FormattedMessage id="AUTH.VERIFYEMAIL.SUBTITLE" />
              </p>
              <p className="text-muted font-weight-bold">
                <FormattedMessage id="AUTH.VERIFYEMAIL.TO_LOGIN" />
              </p>
            </div>
                :
                <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.VERIFYEMAIL.VERIFICATION_FAILED" />

                </h3>
                <p className="text-muted font-weight-bold">
                <FormattedMessage id="AUTH.VERIFYEMAIL.VERIFICATION_FAILED_DESC" />

                </p>
                </div>}

        </div>
        )


}

export default injectIntl(VerifyEmail)
