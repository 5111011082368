import { Repository } from '../../servics/httpsServics';

const appointments = '/appointments';

export function getAppointmentUpdateData(data) {
  return Repository.get(appointments + '/get-update-time-appointments', {
    params: data
  });
}

export function updateAppointmentUpdateData(paramsData, bodyData) {
  return Repository.post(appointments + '/update-time-appointments', bodyData, {
    params: paramsData
  });
}
